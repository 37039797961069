export const allowedRouteList = [
  '/how-it-works/',
  '/who-we-help/',
  '/why-were-better/',
  '/testimonials/',
  '/faq/',
  '/about/',
  '/contact/',
  '/scam/',
  '/',
]
