import Image from 'next/image'
import PropTypes from 'prop-types'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { contentfulImageLoader } from './loader'
import { checkWebpSupport } from './image'

/**
 * Enhanced Image component with WebP support and error handling
 * @param {Object} props - Component props
 */
const Img = (props) => {
  const {
    className = '',
    imgClassName = '',
    alt,
    src = '',
    dataTestId = '',
    layout = 'fill',
    objectFit = 'contain',
    height = 1,
    width = 1,
    priority = false,
    isBackgroundImage = false,
    styles = { position: 'relative', height: '30px', width: '30px' },
    objectPosition,
    imgWidth = '100%',
    customLoader = true,
    isPngAvailable = false,
  } = props

  // Memoize contentful check to avoid recalculation
  const isContentful = useMemo(
    () => src?.includes('images.ctfassets.net'),
    [src]
  )

  // Use lazy initial state to avoid unnecessary computation during SSR
  const [supportsWebp, setSupportsWebp] = useState(() => true)
  const [newSrc, setNewSrc] = useState(() => src)
  const [isClient, setIsClient] = useState(false)

  // Run only on client-side
  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleError = useCallback(
    async (e) => {
      if (!isClient) return // Skip during SSR
      if (
        isContentful &&
        supportsWebp &&
        e.target.currentSrc?.includes('webp')
      ) {
        setSupportsWebp(false)

        const fallbackSrc = src.replace('.webp', '.png')
        setNewSrc(isPngAvailable ? fallbackSrc : src)
      }
    },
    [isContentful, supportsWebp, src, isClient, isPngAvailable]
  )

  useEffect(() => {
    if (!isClient) return // Skip during SSR

    let mounted = true

    const initImage = async () => {
      try {
        const isSupported = await checkWebpSupport()
        if (!mounted) return

        if (!isSupported) {
          const fallbackSrc = src.replace('.webp', '.png')
          if (!mounted) return
          setSupportsWebp(false)
          setNewSrc(isPngAvailable ? fallbackSrc : src)
        } else {
          setSupportsWebp(true)
          setNewSrc(src)
        }
      } catch (error) {
        console.error('Image initialization error:', error)
        if (mounted) {
          setNewSrc(src) // Fallback to original source
        }
      }
    }

    initImage()

    return () => {
      mounted = false
    }
  }, [src, isClient, isPngAvailable])

  // Memoize image props to prevent unnecessary recalculations
  const imgProps = useMemo(
    () => ({
      src: newSrc,
      style: { objectFit, objectPosition },
      className: imgClassName,
      priority,
      quality: 80,
      loading: priority ? 'eager' : 'lazy',
      sizes: layout === 'fill' ? '(max-width: 640px) 100vw, 50vw' : undefined,
      ...(isContentful &&
        customLoader && {
          loader: contentfulImageLoader(supportsWebp),
        }),
      ...(layout !== 'fill' && { height, width }),
      onError: handleError,
    }),
    [
      newSrc,
      objectFit,
      objectPosition,
      imgClassName,
      priority,
      layout,
      isContentful,
      customLoader,
      supportsWebp,
      height,
      width,
      handleError,
    ]
  )

  // Memoize container className
  const containerClassName = useMemo(
    () =>
      `${isBackgroundImage ? 'absolute -z-1' : 'relative'} ${
        layout === 'fill' ? `${className} overflow-hidden` : className
      }`,
    [isBackgroundImage, layout, className]
  )

  if (!src) return null

  // Apply responsive and cover styles
  if (layout === 'responsive') {
    imgProps.className = `${imgProps.className} min-h-full max-h-full min-w-full max-w-full aspect-square object-contain`
    imgProps.style = {
      ...imgProps.style,
      height: 'auto',
      width: imgWidth,
    }
    imgProps.sizes = '100vw'
  }

  if (objectFit === 'cover') {
    imgProps.className = `${imgProps.className} object-cover h-full`
  }

  return (
    <div className={containerClassName} data-testid={dataTestId} style={styles}>
      <Image alt={alt} fill={layout === 'fill'} {...imgProps} />
    </div>
  )
}

Img.propTypes = {
  objectPosition: PropTypes.string,
  layout: PropTypes.string,
  objectFit: PropTypes.string,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  dataTestId: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  isBackgroundImage: PropTypes.bool,
  priority: PropTypes.bool,
  styles: PropTypes.object,
  imgWidth: PropTypes.string,
  customLoader: PropTypes.bool,
  isPngAvailable: PropTypes.bool,
}

export default React.memo(Img)
