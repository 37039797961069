/**
 * Checks if the browser supports WebP format
 * @returns {Promise<boolean>} Promise resolving to true if WebP is supported
 */
export const checkWebpSupport = () => {
  if (typeof window === 'undefined') return Promise.resolve(true)

  const webpData = '/next-assets/pixel-guide.webp'

  return new Promise((resolve) => {
    const image = new Image()
    image.onload = () => resolve(true)
    image.onerror = () => resolve(false)
    image.src = webpData
  })
}
